import { Seta, } from './icons'


const More = ({ text }) => {

    const colorPrimary = '#EA1D2C'
    return (
        <>
            <a href="https://sneptop.com/contact" className="no-underline cursor-pointer text-primary mb-4 mt-2 flex flex-row justify-around items-center">
                <div style={{ color: colorPrimary, }}>{text}</div>
                <Seta />
            </a>
        </>
    )
}

export default More;
