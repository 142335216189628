import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';

import CardDark from "../../assets/Projects/finance-bank/card-dark.png";
import ProductDark from "../../assets/Projects/finance-bank/card.png";
import Login from "../../assets/Projects/finance-bank/login.png";
import Card from "../../assets/Projects/finance-bank/transactions.png";
import Home from "../../assets/Projects/finance-bank/home.png";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

const CardCarroussel = () => {
    return <div style={{
        //width: '100%',
        //padding: '20px',
        //
        //borderRadius: '10px',
        //verticalAlign: 'middle'
    }}>
        <Carousel
            swipeable={false}
            draggable={false}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}//this.props.deviceType !== "mobile" ? true : false}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            //deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-80-px"
        >
            <img style={{ width: '100%', padding: '20px', borderRadius: '10px', verticalAlign: 'middle' }}
                src={CardDark} />
            <img style={{ width: '100%', padding: '20px', borderRadius: '10px', verticalAlign: 'middle' }}
                src={ProductDark} />
            <img style={{ width: '100%', padding: '20px', borderRadius: '10px', verticalAlign: 'middle' }}
                src={Login} />
            <img style={{ width: '100%', padding: '20px', borderRadius: '10px', verticalAlign: 'middle' }}
                src={Card} />
            <img style={{ width: '100%', padding: '20px', borderRadius: '10px', verticalAlign: 'middle' }}
                src={Home} />
        </Carousel>
    </div>
}
export default CardCarroussel