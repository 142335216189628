import React from "react";
//import GitHubCalendar from "react-github-calendar";
import View from 'components/Custom/View'
import { theme } from "../../constants/theme";

//TODO fix
function Github() {
  const colourTheme = {
    background: "transparent",
    text: "#ffffff",
    grade4: "#8400b8",
    grade3: "#b22ff4",
    grade2: "#b265f6",
    grade1: "#c084f5",
    grade0: "#ecd9fc",
  };
  return (
    <View row style={{ justifyContent: "center", paddingBottom: "10px" }}>
      {/*<h1 className="project-heading" style={{ paddingBottom: "20px" }}>
        Days I <strong className={theme.colors.primary}>Code</strong>
      </h1>*/}
      {/*<GitHubCalendar
        username="tuliohs"
        blockSize={15}
        blockMargin={5}
        theme={colourTheme}
        fontSize={16}
      />*/}
    </View>
  );
}

export default Github;
