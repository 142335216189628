import React from "react";
import { CgCPlusPlus } from "react-icons/cg";
import {
    DiJavascript1,
    DiReact,
    DiNodejs,
    DiMongodb,
    DiPython,
    DiGit,
} from "react-icons/di";
import {
    SiKotlin, SiMysql, SiDocker, SiFirebase, SiCsharp, SiJavascript,
    SiDotnet,
    SiOracle, SiTypescript, SiNextdotjs as SiNextDotJs
} from "react-icons/si";
//import View from "components/Custom/View";

const techItems = [
    { name: 'C#', icon: <SiCsharp />, color: '#8625D2' },
    { name: '.Net Framework', icon: <SiDotnet />, color: '#fff' },
    { name: 'Javascript', icon: <SiJavascript />, color: '#f7df1e' },
    { name: 'Typescript', icon: <SiTypescript />, color: '#3178c6' },
    { name: 'React', icon: <DiReact />, color: '#2ac7e3' },
    { name: 'React Native', icon: <DiReact />, color: '#4763ff' },
    { name: 'Next JS', icon: <SiNextDotJs />, color: '#000000' },
    { name: 'Nodejs', icon: <DiNodejs />, color: '#8cc84b' },
    { name: 'Mongodb', icon: <DiMongodb />, color: '#04d361' },
    { name: 'Kotlin', icon: <SiKotlin />, color: '#7877ef' },
    { name: 'Oracle', icon: <SiOracle />, color: '#eb3223' },
    //{ name: 'Mysql', icon: <SiMysql />, color: '#0389ff' },
    { name: 'Docker', icon: <SiDocker />, color: '#0389ff' },
]
function Tecnologies() {
    return (
        <ul style={{
            justifyContent: "center",
            paddingBottom: "120px",
            flexWrap: 'wrap',
            maxWidth: '800px',
            flexDirection: 'row',
            display: 'flex',
            color: "#fff"
        }}>
            {
                techItems.map(e => (
                    <div /*xs={4} md={2}*/ className=" logo-holder " >
                        <div className="image-tech tech-icons-modern" style={{ color: e.color }}>
                            {e.icon}
                        </div>
                        <div class="text-image-holder"
                            style={{ //fontSize: 16,
                                textOverflow: 'ellipsis',
                                color: e.color
                            }}
                        >
                            {e.name}
                        </div>
                    </div>
                ))
            }
        </ul>
    );
}

export default Tecnologies;
