import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "../containers/Projects/ProjectCards";
import Particle from "components/Particle";

import FinanceBank from "../assets/Projects/finance-bank/card-dark.png";
import SnepTop from "../assets/Projects/sneptop/mosaico.png";
import Forgetion from "../assets/Projects/forgetion/factory.png";
import ChatOnline from "../assets/Projects/chat.png";
import WallPaint from "../assets/Projects/wall-paint/gif-paint.gif";
import { useTranslation } from "react-i18next";

function Projects() {
  const { t } = useTranslation()
  return (
    <Container id="projects" fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          {t("project.title")}
          {/*<strong className="purple">Works </strong>*/}
        </h1>
        <p //style={{ color: "white" }}
        >
          {/*Aqui estão alguns projetos que tenho trabalhado recentemente.*/}
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={SnepTop}
              isBlog={false}
              title={t("project.sneptop")}
              description={t("project.sneptopD")}
              link="https://sneptop.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={WallPaint}
              isBlog={false}
              title={t("project.wallpaint")}
              description={t("project.wallpaintD")}
              link="https://github.com/tuliohs/wall-paint"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Forgetion}
              isBlog={false}
              title={t("project.forgetion")}
              description={t("project.forgetionD")}
              link="https://github.com/tuliohs/web-schedule"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ChatOnline}
              isBlog={false}
              title={t("project.chat")}
              description={t("project.chatD")}
              link="https://github.com/tuliohs/chat-real-time"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              isCarrousel={true}
              imgPath={FinanceBank}
              isBlog={false}
              title={t("project.financebank")}
              description={t("project.financebankD")}
              link="https://github.com/tuliohs/finance-bank"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
