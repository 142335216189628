import View from "components/Custom/View";
import { Seta, Shop, HandPhone } from './icons'
import { useTranslation } from 'react-i18next'
import More from "containers/Home/More";


const headerStyle = `
  background-color: #ffffff;
  /*min-height: calc(100vh - 6rem);*/
`;

const Tiles = () => {

    const { t } = useTranslation()

    const keys = {
        card1: {
            title: t("home.makeApp"),
            description: t("home.makeAppD"),
            bt: t("home.makeAppB"),
        },
        card2: {
            title: t("home.inCompany"),
            description: t("home.inCompanyD"),
            bt: t("home.inCompanyB"),
        }
    }

    const colorPrimary = '#EA1D2C'
    const hanldePreRegister = () => {

    }
    return (
        <header className={(headerStyle)}
            style={{ fontWeight: 500 }}
        >
            <div className={(`max-w-4xl mx-auto py-5 pb-10 px-14 sm:px-6 lg:px-8`)}            >
                <div style={{ color: "#000000" }}    >
                    <div  >
                        <div>
                            <View container direction="row" style={{
                                display: "flex", justifyContent: "space-around",


                                flexWrap: 'wrap'
                            }}>
                                <View item direction="column" style={{ maxWidth: 200 }}>
                                    <a
                                        style={{ cursor: "pointer" }}
                                        onClick={hanldePreRegister}
                                    >
                                        <HandPhone />
                                        <h6 style={{ color: '#1A202C', marginTop: 12 }} className="title-home-card">{keys.card1.title}
                                        </h6>

                                        <p className="mb-4">
                                            {keys.card1.description}
                                        </p>
                                        <More text={keys.card1.bt} />
                                    </a>
                                </View>

                                <View item direction="column" style={{
                                    maxWidth: 200,
                                }}>
                                    <a
                                        style={{ cursor: "pointer" }}
                                        onClick={hanldePreRegister} >
                                        <Shop />
                                        <View
                                            //as="h6"
                                            //color="#000000"
                                            //fontSize={{ base: '16px', md: '19px', lg: '22px' }}
                                            mb={4}
                                            style={{ color: '#1A202C', marginTop: 12 }}
                                        //fontWeight="xBold"
                                        ><h6 className="title-home-card">{keys.card2.title}
                                            </h6>
                                        </View>
                                        <p className="mb-4">
                                            {keys.card2.description}
                                        </p>
                                        <More text={keys.card2.bt} />
                                    </a>
                                </View>
                            </View>
                        </div>
                    </div> </div>
            </div>
        </header >
    )
}

export default Tiles;
