import React from "react";
import View from "components/Custom/View";
import { useTranslation } from 'react-i18next'
import { BrasilFlag, EuaFlag } from 'components/I18n/assets'
import Flag from 'components/I18n/Flag'
//import { Translator } from '../I18n'

const languageitems = [
  { name: 'Português' },
  { name: 'Inglês' },
]

function LanguageSupport() {

  const { i18n } = useTranslation()

  function handleChangeLanguage(language) {
    i18n.changeLanguage(language)
  }

  const selectedLanguage = i18n.language

  return (
    <View row style={{ justifyContent: "center", paddingBottom: "10px" }}>
      <h1 className="project-heading" style={{ paddingBottom: "20px", marginTop: 60 }}>
        Language Suport
      </h1>
      <View>
        <div className="flex flex-row justify-center  ">
          <button
            style={{ opacity: selectedLanguage !== 'pt-BR' ? 0.8 : 1 }}
            onClick={() => handleChangeLanguage('pt-BR')}
            className="language-button">
            <div className=" flex flex-row justify-around">
              {languageitems[0].name}
              <Flag
                image={BrasilFlag}
                isSelected={selectedLanguage === 'pt-BR'}
              />
            </div>
          </button>
          <button
            style={{ opacity: selectedLanguage !== 'en-US' ? 0.8 : 1 }}
            onClick={() => handleChangeLanguage('en-US')}

            className="language-button">

            <div className=" flex flex-row justify-around">
              {languageitems[1].name}
              <Flag
                image={EuaFlag}
                isSelected={selectedLanguage === 'en-US'}
              /></div>
          </button>
        </div>
      </View>
    </View>
  );
}

export default LanguageSupport;