import React from "react";
import Particle from "components/Particle";
import Github from "../containers/About/Github";
//import Techstack from "components/About/Techstack.old";
//import Aboutcard from "components/About/AboutCard";
//import laptopImg from "../assets/logo.png";
import LanguageSupport from "../containers/About/LanguageSupport";
import Tecnologies from "../containers/About/Tecnologies";

import '../style.css'
import View from "components/Custom/View";
import { theme } from "../constants/theme";
import { useTranslation } from "react-i18next";

function About() {
    const {t}=useTranslation()
    return (
        <div id="about" >
            {/*<Particle />*/}
            <View style={{
                //backgroundColor: "#29292e",,
                with: "100%",
                paddingTop: 80,
                //backgroundColor: "#764abc",
                //backgroundColor: "#1e2746"
            }}
                className="section-tec"
            >
                <h1 className="project-heading"
                    style={{ color: "#fff" }}
                >
                   {t("about.title")}
                </h1>
                <View style={{
                    justifyContent: 'center',
                    display: 'flex'
                }} >
                    <Tecnologies />
                </View>

            </View>
            <View container fluid className="about-section">
                {/*<Particle />*/}

                <View container>
                    {/*<View row style={{ justifyContent: "center", padding: "10px" }}>
                        <View
                            md={7}
                            style={{
                                justifyContent: "center",
                                paddingTop: "30px",
                                paddingBottom: "50px",
                            }}
                        >
                            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
                                Know Who <strong className={theme.colors.primary}>I'M</strong>
                            </h1>
                            <Aboutcard />
                        </View>
                        <View
                            md={5}
                            style={{ paddingTop: "120px", paddingBottom: "50px" }}
                            className="about-img"
                        >
                            <img src={laptopImg} alt="about" className="img-fluid" />
                        </View>
                    </View>*/}
                    {/*<h1 className="project-heading">
                    Professional <strong className={theme.colors.primary}>Skillset </strong>
                </h1>
                <Techstack />*/}



                    {/*<h1 className="project-heading">
                    <strong className={theme.colors.primary}>Tools</strong> I use
                </h1>
                <Toolstack />*/}

                    <Github />
                </View>
                <LanguageSupport />
            </View>
        </div>
    );
}

export default About;
