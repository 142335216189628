import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo from "../assets/logo.png";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { CgGitFork } from "react-icons/cg";
import { ImBlog } from "react-icons/im";
import ReactIconsAi, {
  AiFillStar,
  AiOutlineHome,
  AiOutlineFundProjectionScreen,
  AiOutlineUser,
} from "react-icons/ai";

import { CgFileDocument } from "react-icons/cg";
import { theme } from "../constants/theme";

function NavBar({ onePage }) {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);


  const menuItems = [
    { title: 'Home', id: 'home', path: '/', icon: <AiOutlineHome style={{ marginBottom: "2px" }} /> },
    { title: 'About', id: 'about', path: '/about', icon: <AiOutlineUser style={{ marginBottom: "2px" }} /> },
    { title: 'Projects', id: 'projects', path: '/project', icon: <AiOutlineFundProjectionScreen style={{ marginBottom: "2px" }} /> },
    //{ title: 'Resume', path: '/resume', icon: <CgFileDocument style={{ marginBottom: "2px" }} /> },
    //{ title: 'Blog', path: '/blog', icon: <ImBlog style={{ marginBottom: "2px" }} /> },
  ]
  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      style={{ backgroundColor: theme.colors.navBar }}
      className={navColour ? "sticky" : "navbar"}
    >
      <Container >
        <Navbar.Brand href="/#">
          <img src={logo} className="logo" alt="brand" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto" defaultActiveKey="#home">
            {onePage ?
              menuItems.map(e => (
                <Nav.Item>
                  <Nav.Link
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} as="a" href={'#' + e.id} onClick={() => updateExpanded(false)}>
                    {e.icon}<div className="ml-2 py-2"> {e.title}</div>
                  </Nav.Link>
                </Nav.Item>
              )) :
              menuItems.map(e => (
                <Nav.Item>
                  <Nav.Link as={Link} to={e.path} onClick={() => updateExpanded(false)}>
                    {e.icon} {e.title}
                  </Nav.Link>
                </Nav.Item>

              ))
            }
            {/*<Nav.Item className="fork-btn">
              <Button
                href="https://github.com/tuliohs"
                target="_blank"
                className="fork-btn-inner"
              >
                <CgGitFork style={{ fontSize: "1.2em" }} />{" "}
                <AiFillStar style={{ fontSize: "1.1em" }} />
              </Button>
            </Nav.Item>*/}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
