export default {
  translations: {
    home: {
      makeApp: 'Faça seu aplicativo conosco',
      makeAppD: 'Construção rápida de aplicativos com o que há de mais moderno.',
      makeAppB: 'Saiba mais',
      inCompany: 'Desenvolvimento in company',
      inCompanyD: 'Vamos trabalhar nos seus projetos juntos.',
      inCompanyB: 'Contate-nos',

      message: 'Olá Mundo!'
    },
    about: {
      title: "Tecnologias que apreciamos"
    },
    project: {
      title: "Projetos Recentes",
      sneptop: "Sneptop Delivery",
      sneptopD: "Sistema para delivery e lojas, oferece gestão de pedidos e entregas a partir de uma plataforma dinâmica e customizavel.",
      wallpaint: "Wall Paint - Animações 3D",
      wallpaintD: "Sistema criado com React-Native para cálcular o número de latas de tinta que são necessárias para pinturas de paredes em determinados ambientes, útiliza animações 3D.",
      forgetion: "Forgetion",
      forgetionD: "Forgetion é um aplicativo baseado na curva de esquecimento para calcular o tempo entre os intervalos de estudo para optimização de aprendizagem.",
      chat: "Chat Online",
      chatD: "Implementa um sistema de chat online via conexão real-time com web-socket utilizando React JS + Socket.IO.",
      financebank: "Finance Bank",
      financebankD: "Sistema criado com React.JS para simular transações bancárias. Funções disponíveis: Depósito, Transferência e Pagamento.",
    },
    footer: {
      makeApp: 'Faça seu aplicativo conosco',
      inCompany: 'Desenvolvimento in company',
      deliverySystem: 'Crie seu sistema de delivery',
    }
  }
}

