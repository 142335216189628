import React from 'react'

const Flag = ({ image, isSelected, ...props }) => (
  <img

    width="25px"
    height="25px"
    alt="flag" src={image}
    className={isSelected ? ' selected' : ''}
    {...props} />
)

export default Flag