import { sizes } from './sizes';

export const THEMES = {
    LIGHT: 'LIGHT',
    DARK: 'DARK'
};


const themesOptions = [
    {
        name: THEMES.LIGHT,
        colors: {
            active: 'grey',
            background: '#f4f6f8',
            primary: '#EA1D2C',//'purple',// '#EA1D2C',
            //main: '#f44336',
            secondary: '#3EA6FF',
            text: 'green',
            navBar: '#141a31'
        },
    },
    {
        name: THEMES.DARK,

    }
]

const theme = themesOptions[0]
export { sizes, theme }
