import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { BiLinkExternal } from "react-icons/bi";
import { theme } from '../../constants/theme'
import CardCarroussel from "./CardCarroussel";

function ProjectCards(props) {
  return (
    <Card className="project-card-view">
      {
        props?.isCarrousel ?
          <CardCarroussel />
          : <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      }
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>
        <Button style={{ backgroundColor: theme.colors.navBar }} href={props.link} target="_blank">
          <BiLinkExternal /> &nbsp;
          {props.isBlog ? "View Blog" : "View Project"}
        </Button>
      </Card.Body>
    </Card >
  );
}
export default ProjectCards;
