export default {
  translations: {
    home: {
      makeApp: 'Make your app with us',
      makeAppD: 'Rapidly build apps with the latest state-of-the-art.',
      makeAppB: 'Learn more',
      inCompany: 'In company development',
      inCompanyD: "Let's work on your projects together.",
      inCompanyB: 'Contact us',
      message: 'Hello World!',
      icode: "Days I Code",
    },
    about: {
      title: "Technologies we appreciate"
    },
    project: {
      title: "Recent Projects",
      sneptop: "Sneptop Delivery",
      sneptopD: "System for delivery and stores, offers management of orders and deliveries from a dynamic and customizable platform.",
      wallpaint: "Wall Paint - 3D Animations",
      wallpaintD: "System created with React-Native to calculate the number of paint cans that are needed for wall painting in certain environments, uses 3D animations.",
      forgetion: "Forgetion",
      forgetionD: "Forgetion is an application based on the forgetting curve to calculate the time between study intervals for learning optimization.",
      chat: "Online Chat",
      chatD: "Implement an online chat system via real-time connection to web-socket using React JS + Socket.IO.",
      financebank: "Finance Bank",
      financebankD: "System created with React.JS to simulate banking transactions. Available functions: Deposit, Transfer and Payment.",
    },
    footer: {
      makeApp: 'Make your app with us',
      inCompany: 'In company development',
      deliverySystem: 'Create your delivey system',
    }
  }
}